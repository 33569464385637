
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-article-list-tab',
  props: ['filter'],
  data() {
    return {
      totalPages: 2,
      page: 1,
      pageNumber: 1,
      menuSports: false,
      menuOverSports: false,
      listOverSports: false,
      sportsFilter: '',
      currentPage: 1,
      pagination: {
        page: 1,
        rowsPerPage: 2,
      },
      category: null,
      subCategory: null,
      filterType: null,
    };
  },
  methods: {
    ...mapActions({
      doFetch: 'article/list/doFetch',
      doLoadMore: 'article/list/doLoadMore',
      doFetchPrevious: 'article/list/doFetchPrevious',
      doFilter: 'article/list/doFilter',
      setMountedTable: 'article/list/setMountedTable',
      bindFiltersRef: 'article/list/bindFiltersRef',
      doFetchSports: 'layout/doFetchSports',
      doFilterSports: 'article/list/doFilterSports',
      setNext: 'article/list/setNext',
      resetLastAndFirstVisible:
        'article/list/resetLastAndFirstVisible',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    namePresenter(value) {
      return this.isRTL ? value.ar : value.en;
    },
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',

      article: 'article/list/rows',
      next: 'article/list/next',
      previous: 'article/list/previous',
      mountedTable: 'article/list/mountedTable',
      loading: 'article/list/loading',
      filters: 'article/list/filters',
      currentLanguageCode: 'layout/currentLanguageCode',
      sports: 'layout/sports',
      rowsOld: 'article/list/rowsOld'
    }),
  },
};
