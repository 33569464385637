
import { mapGetters, mapActions } from 'vuex';
import ArticleListFilter from '@/modules/article/components/article-list-filter.vue';
import ArticleListTab from '@/modules/article/components/article-list-tab.vue';
import moment from 'moment';
import { ArticleModel } from '@/modules/article/article-model';
import 'animate.css';

export default {
  name: 'app-article-list-table',
  data() {
    return {
      tab: 'all',
      value: '',
      value2: '',
      showMenu: true,
      search: undefined,
      filter: '',
      page: 1,
      currentPage: 1,
      nextPage: null,
      allArticles: [],
      // this is the max number of article per page
      totalPages: 6,
      searchText: '',
      endcode: '',
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
      },

      category: null,
      subCategory: null,
      sportsFilter: '',
      filterType: null,
      pageNumber: 1,
      tabID: null,
      rowsOld: [],
      navigateBackwardForwardBoolean: false,
      hasSubCategories: false,
    };
  },
  components: {
    [ArticleListFilter.name]: ArticleListFilter,
    [ArticleListTab.name]: ArticleListTab,
  },
  computed: {
    getArticle() {
      return this.article.slice(
        (this.page - 1) * this.totalPages,
        (this.page - 1) * this.totalPages + this.totalPages,
      );
    },
    rowsToBeDisplayed() {
      if (this.article.length > 0 || !this.loading) {
        return this.article;
      } else {
        return this.rowsOld;
      }
    },
    searchTextComputed() {
      return this.searchText;
    },
    rowsPerPageOptions() {
      return [2];
    },
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',

      article: 'article/list/rows',
      mountedTable: 'article/list/mountedTable',
      loading: 'article/list/loading',
      filters: 'article/list/filters',
      currentLanguageCode: 'layout/currentLanguageCode',
      sports: 'layout/sports',
      next: 'article/list/next',
      previous: 'article/list/previous',
    }),
  },
  watch: {
    article(newval, oldval) {
      console.log('newval', newval);
      console.log('oldval', oldval);
    },
    async searchTextComputed(newVal) {
      if (newVal == '') {
        await this.doResetPagination();
        return await this.fetchArticles();
      }
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'article/list/doFetch',
      doFilter: 'article/list/doFilter',
      doFilterSearch: 'article/list/doFilterSearch',
      setMountedTable: 'article/list/setMountedTable',
      bindFiltersRef: 'article/list/bindFiltersRef',
      resetLastAndFirstVisible:
        'article/list/resetLastAndFirstVisible',
      doLoadMore: 'article/list/doLoadMore',
      doFetchPrevious: 'article/list/doFetchPrevious',
      doFilterSports: 'article/list/doFilterSports',
    }),
    doResetPagination() {
      this.pageNumber = 1;
      this.resetLastAndFirstVisible();
      // if(!this.hasSubCategories){
      //   this.$router.push({
      //   query: {
      //     page: this.pageNumber,
      //     search: this.searchText,
      //     filter: this.tab,
      //   },
      // });
      // }
    },
    getItemsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 8;
      }
      // if(screen.lt.xl){
      return 9;
      // }
    },

    filterSportsTab() {
      this.tab = 'Sports';
      return this.fetchArticles();
    },
    filterCommitteesTab() {
      this.tab = 'committees';
      return this.fetchArticles();
    },
    filterBoardOfDirectorsTab() {
      this.tab = 'Board of Directors';
      return this.fetchArticles();
    },
    async fetchArticles() {
      console.log(this.hasSubCategories);
      var search;
      var strEndCode = this.searchText.slice(
        this.searchText.locale - 1,
        this.searchText.length,
      );
      if (this.tab == 'all') {
        search = {
          searchText: this.searchText,
          endcode:
            this.searchText +
            String.fromCharCode(
              strEndCode.charCodeAt(0) + 1,
            ),
          id: 'all',
          sportsFilter: this.sportsFilter,
          sportsFilterType: this.filterType,
          pageNumber: this.pageNumber,
          navigateBackwardForwardBoolean:
            this.navigateBackwardForwardBoolean,
        };
      } else {
        const tabs = this.filters.filter((el) => {
          return el.name.en == this.tab;
        });
        var tabId = tabs[0].id;
        this.tabID = tabId;
        search = {
          searchText: this.searchText,
          endcode:
            this.searchText +
            String.fromCharCode(
              strEndCode.charCodeAt(0) + 1,
            ),
          id: tabId,
          sportsFilter: this.sportsFilter,
          sportsFilterType: this.filterType,
          pageNumber: this.pageNumber,
          navigateBackwardForwardBoolean:
            this.navigateBackwardForwardBoolean,
        };
      }
      this.endcode = search.endcode;
      if (this.navigateBackwardForwardBoolean == false)
        this.$router.push({
          query: {
            page: this.pageNumber,
            search: this.searchText,
            filter: this.tab,
            sportsFilter: this.sportsFilter,
            sportsFilterType: this.filterType,
          },
        });
      console.log(search);
      this.hasSubCategories = false;
      return await this.doFilterSearch(search);
    },

    presenter(row, fieldName) {
      const val = ArticleModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterTitle(row) {
      if (!this.isRTL) {
        const val = ArticleModel.presenter(row, 'title_en');
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = ArticleModel.presenter(row, 'title');
        return val ? val : 'ـــــــ';
      }
    },
    presenterDescription(row) {
      if (!this.isRTL) {
        const val = ArticleModel.presenter(
          row,
          'description_en',
        );
        return val ? val['en'] : 'ـــــــ';
      } else {
        const val = ArticleModel.presenter(
          row,
          'description',
        );
        return val ? val : 'ـــــــ';
      }
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = ArticleModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      // return moment(date).locale(this.currentLanguageCode).format("hh:mm a");
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddd D MMM YYYY hh:mm A')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    namePresenter(value) {
      return this.isRTL ? value.ar : value.en;
    },
    async navigateBackwardForward() {
      this.navigateBackwardForwardBoolean = true;

      console.log('HERE', this.$route);
      this.pageNumber =
        this.$route.query.page &&
        parseInt(this.$route.query.page) > 0
          ? parseInt(this.$route.query.page)
          : 1;
      this.searchText = this.$route.query.search
        ? this.$route.query.search
        : '';
      this.tab = this.$route.query.filter
        ? this.$route.query.filter
        : 'all';
      this.sportsFilter = this.$route.query.sportsFilter
        ? this.$route.query.sportsFilter
        : '';
      this.filterType = this.$route.query.sportsFilterType
        ? this.$route.query.sportsFilterType
        : '';

      window.addEventListener('scroll', () => {
        this.showMenu = false;
      });
      if (!this.mountedTable) {
        await this.bindFiltersRef();
        this.setMountedTable(true);
      }
      this.fetchArticles();
    },
    async loadMore() {
      this.rowsOld = this.article;
      this.navigateBackwardForwardBoolean = false;
      this.pageNumber++;
      const payload = {
        id: this.tab,
        sportsFilter: this.sportsFilter,
        type: this.filterType,
        searchText: this.searchText,
        endcode: this.endcode,
        pageNumber: this.pageNumber,
      };
      this.$router.push({
        query: {
          page: this.pageNumber,
          search: this.searchText,
          filter: this.tab,
          sportsFilter: this.sportsFilter,
          sportsFilterType: this.filterType,
        },
      });
      await this.doLoadMore(payload);
    },
    async loadPrevious() {
      this.navigateBackwardForwardBoolean = false;
      this.pageNumber--;
      const payload = {
        id: this.tab,
        sportsFilter: this.sportsFilter,
        type: this.filterType,
        searchText: this.searchText,
        endcode: this.endcode,
      };
      this.$router.push({
        query: {
          page: this.pageNumber,
          search: this.searchText,
          filter: this.tab,
          sportsFilter: this.sportsFilter,
          sportsFilterType: this.filterType,
        },
      });
      await this.doFetchPrevious(payload);
    },
  },
  async mounted() {
    console.log('MOUNTED', this.$route);
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardForward();
    };
  },
  async created() {
    this.pageNumber =
      this.$route.query.page &&
      parseInt(this.$route.query.page) > 0
        ? parseInt(this.$route.query.page)
        : 1;
    this.searchText = this.$route.query.search
      ? this.$route.query.search
      : '';
    this.tab = this.$route.query.filter
      ? this.$route.query.filter
      : 'all';
    this.sportsFilter = this.$route.query.sportsFilter
      ? this.$route.query.sportsFilter
      : '';
    this.filterType = this.$route.query.sportsFilterType
      ? this.$route.query.sportsFilterType
      : '';

    window.addEventListener('scroll', () => {
      this.showMenu = false;
    });
    if (!this.mountedTable) {
      await this.bindFiltersRef();
      this.setMountedTable(true);
      this.fetchArticles();
    }
  },
};
